import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ja from "./locales/ja.json";

i18n.use(initReactI18next).init({
  lng: "ja",
  fallbackLng: false,
  resources: {
    ja,
  },
});
