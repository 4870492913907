"use client";
import { QueryClientProvider } from "@tanstack/react-query";
import { SessionProvider } from "next-auth/react";
import { Toaster } from "react-hot-toast";
import { RecoilRoot } from "recoil";

import { CFC } from "shared/types";

import { AuthProvider } from "~/features/auth";
import { NotificationsProvider } from "~/features/notifications";
import { queryClient } from "~/lib/react-query";

import "shared/features/i18n/config";

export const Providers: CFC = ({
  children,
}) => {
  return (
    <SessionProvider>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <NotificationsProvider>
              <Toaster position="top-center" />
              {children}
            </NotificationsProvider>
          </AuthProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </SessionProvider>
  );
};
